<template>
  <div class="library">
    <div class="title">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="订单号" prop="orderNo">
          <el-input
            placeholder="请输入订单号"
            v-model="searchForm.number"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            placeholder="请输入手机号"
            v-model="searchForm.phone"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="通讯板号" prop="communicationBoardNo">
          <el-input
            placeholder="请输入通讯板号"
            v-model="searchForm.communicationBoardNo"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="押金状态" prop="status">
          <el-select
            ref="searchFormStatus"
            v-model="searchForm.status"
            placeholder="请选择押金状态"
            clearable
          >
            <el-option
              v-for="opt in DEAL_STATUS"
              :label="opt.label"
              :key="opt.value"
              :value="opt.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="orderNo" fixed width="200" label="订单号">
        </el-table-column>
        <el-table-column
          prop='communicationBoardNo'
          width="130"
          label="设备通讯板号"
        >
        </el-table-column>
        <el-table-column prop="money"  label="支付方式">
          <template slot-scope="{ row }">
            {{ $changeObj(PAY_TYPE)[row.payType] }}
          </template>
        </el-table-column>
        <el-table-column prop="name"  label="姓名">
        </el-table-column>
        <el-table-column prop="phone" width="120" label="手机号">
        </el-table-column>
        <el-table-column prop="cashPledge"  label="应退押金">
        </el-table-column>
        <el-table-column
          prop="occupancyExpenses"
          label="设备占用费"
        >
        </el-table-column>
        <el-table-column prop="realRefund" label="实际退押">
        </el-table-column>
        <el-table-column prop="createTime" label="处理结果">
          <template slot-scope="{ row }">
            {{ $changeObj(DEAL_STATUS)[row.status] }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="发起时间" min-width="250">
        </el-table-column>
        <el-table-column fixed="right" width="200" label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" @click="ensure(row)" v-if="row.status == 1" >确认</el-button>
            <el-button type="text" @click="ensure(row)" v-if="row.status == 3" >再次确认</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="申请退款"
      :visible.sync="dialogVisible"
      width="30%"
      >
      <el-form class="demo-form-inline">
        <el-form-item label="应退押金(元)">
          <el-input v-model="returnMoney" disabled ></el-input>
        </el-form-item>
        <el-form-item label="设备占用额(元)">
          <p style="color: red"  v-if="row.overtime">该设备已超时{{row.overtime}}天</p>
          <el-input v-model="setMoney"  @input="getSet"></el-input>
        </el-form-item>
        <el-form-item label="实际退还押金(元)">
          <el-input v-model="realMoeny" disabled ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveApply">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryRefundRecordList, applyReturnCash } from "@/api/order";
import { DEAL_STATUS, PAY_TYPE } from "@/js/constant";
import Reg from '@/js/reg'

export default {
  data() {
    return {
      DEAL_STATUS,
      PAY_TYPE,
      searchForm: {
        number: "",
        communicationBoardNo: ""
      },
      beginIndex: 1,
      pageSize: 20,
      list: [],
      total: 0,
      row: {},
      dialogVisible: false,
      returnMoney: 0,
      setMoney: 0,
      realMoeny: 0,
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.searchFormStatus.blur()
        _this.onSearch()
      }
    };
    this.queryRefundRecordList();
    console.log(this.row.orderNo,'this.row.orderNo');

  },
  computed: {

  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.queryRefundRecordList();
    },
    async queryRefundRecordList() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        beginIndex: page,
        pageSize: this.pageSize
      };
      const res = await queryRefundRecordList(params);
      this.list = res.ldata;
      this.total = res.mdata.total || 0;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.queryRefundRecordList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    ensure(row) {
      console.log(row);
      this.row = row;

      this.returnMoney =  row.cashPledge || 0
      this.setMoney = row.occupancyExpenses || 0
      this.realMoeny = (this.returnMoney -  this.setMoney || 0).toFixed(2);
      if (row.isShip == 0 ) {
          this.dialogVisible = true
      } else {
        this.$confirm('是否退押金?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const params = {
            subOrderNo: this.row.orderNo,
            number: this.row.number,
          }
          try {
            await applyReturnCash(params)
            this.$message.success('操作成功')
            this.onSearch()
          } catch (error) {

        }
        }).catch(() => {

        });
      }

    },
    getSet(val) {
      if(!Reg.isDigit(val)) {
        this.$message.error('请输入正确金额')
        return
      }
      this.realMoeny = (this.returnMoney - this.setMoney).toFixed(2);
    },
    async saveApply() {
      if(!Reg.isDigit(this.setMoney)) {
        this.$message.error('请输入正确金额')
        return
      }
      if (this.returnMoney < this.setMoney) {
         this.$message.error('设备费用需要小于应退押金')
        return
      }
      const params = {
        number: this.row.number,
        returnAmount: this.realMoeny,
      }
      try {
        await applyReturnCash(params)
        this.$message.success('操作成功')
        this.onSearch()
        this.dialogVisible = false
      } catch (error) {

      }

    }
  }
};
</script>

<style scoped>
.library {
  padding-bottom: 80px;
}
</style>
